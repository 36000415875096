import Head from 'next/head';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useTheme } from 'next-themes';
import NextLink from 'next/link';
import cn from 'classnames';
import Footer from 'components/Footer';
import MobileMenu from 'components/MobileMenu';

function NavItem({ href, text }) {
  const router = useRouter();
  const isActive = router.asPath === href;

  return (
    <NextLink
      href={href}
      className={cn(
        isActive
          ? 'font-semibold text-gray-800 dark:text-gray-200'
          : 'font-normal text-gray-600 dark:text-gray-400',
        'hidden md:inline-block p-1 sm:px-3 sm:py-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition-all'
      )}
    >
      <span className="capsize">{text}</span>
    </NextLink>
  );
}

export default function Container(props) {
  const [mounted, setMounted] = useState(false);
  const { resolvedTheme, setTheme } = useTheme();

  // After mounting, we have access to the theme
  useEffect(() => setMounted(true), []);

  const { children, ...customMeta } = props;
  const router = useRouter();
  const meta = {
    title: 'Quinn Sprouse – Data Driven Developer',
    description: `Front-end Engineer`,
    image: 'https://sprouse.me/static/images/banner.png',
    type: 'website',
    ...customMeta
  };

  return (
    <div className="bg-soft-violet dark:bg-gray-900">
      <Head>
        <title>{meta.title}</title>
        <link rel="icon" type="image/svg+xml" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%22256%22 height=%22256%22 viewBox=%220 0 100 100%22><rect width=%22100%22 height=%22100%22 rx=%2220%22 fill=%22%23000000%22></rect><path fill=%22%23fff%22 d=%22M48.11 17.02L48.11 17.02Q52.61 17.02 54.95 18.05Q57.29 19.08 59.09 20.34L59.09 20.34Q60.17 20.97 60.84 21.29Q61.52 21.60 62.15 21.60L62.15 21.60Q63.59 21.60 64.13 17.64L64.13 17.64L66.20 17.64Q66.11 19.17 65.97 21.29Q65.84 23.40 65.80 26.87Q65.75 30.33 65.75 36.00L65.75 36.00L63.68 36.00Q63.41 31.68 61.79 27.72Q60.17 23.76 57.06 21.24Q53.96 18.72 48.92 18.72L48.92 18.72Q44.15 18.72 41.05 21.60Q37.94 24.48 37.94 29.16L37.94 29.16Q37.94 33.21 40.01 35.96Q42.08 38.70 45.55 40.91Q49.01 43.11 53.06 45.63L53.06 45.63Q57.74 48.51 61.38 51.35Q65.03 54.18 67.14 57.60Q69.26 61.02 69.26 65.88L69.26 65.88Q69.26 71.64 66.65 75.42Q64.04 79.20 59.72 81.09Q55.40 82.98 50.18 82.98L50.18 82.98Q45.41 82.98 42.62 81.91Q39.83 80.82 37.85 79.66L37.85 79.66Q35.87 78.39 34.79 78.39L34.79 78.39Q33.35 78.39 32.81 82.35L32.81 82.35L30.74 82.35Q30.92 80.46 30.96 77.90Q31.01 75.33 31.05 71.19Q31.10 67.05 31.10 60.75L31.10 60.75L33.17 60.75Q33.53 66.16 35.20 70.83Q36.86 75.52 40.33 78.35Q43.79 81.18 49.55 81.18L49.55 81.18Q53.96 81.18 57.60 78.35Q61.25 75.52 61.25 69.21L61.25 69.21Q61.25 63.72 57.38 60.13Q53.51 56.52 47.21 52.74L47.21 52.74Q42.98 50.13 39.29 47.43Q35.60 44.73 33.39 41.18Q31.19 37.62 31.19 32.58L31.19 32.58Q31.19 27.27 33.58 23.81Q35.96 20.34 39.83 18.68Q43.70 17.02 48.11 17.02Z%22></path></svg>" />
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta property="og:url" content={`https://sprouse.me${router.asPath}`} />
        <link rel="canonical" href={`https://sprouse.me${router.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Quinn Sprouse" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        {meta.date && (
          <meta property="article:published_time" content={meta.date} />
        )}
      </Head>
      <div className="flex flex-col justify-center px-8">
        <nav className="flex items-center justify-between w-full relative max-w-2xl border-gray-200 dark:border-gray-700 mx-auto pt-8 pb-8 sm:pb-16  text-gray-900 bg-gray-50  dark:bg-gray-900 bg-opacity-60 dark:text-gray-100">
          <a href="#skip" className="skip-nav">
            Skip to content
          </a>
          <div className="ml-[-0.60rem]">
            <MobileMenu />
            <NavItem href="/" text="Home" />
            <NavItem href="/guestbook" text="Guestbook" />
            <NavItem href="/uses" text="Uses" />
          </div>
          <button
            aria-label="Toggle Dark Mode"
            type="button"
            className="w-9 h-9 bg-gray-200 rounded-lg dark:bg-gray-600 flex items-center justify-center  hover:ring-2 ring-gray-300  transition-all"
            onClick={() =>
              setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')
            }
          >
            {mounted && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                className="w-5 h-5 text-gray-800 dark:text-gray-200"
              >
                {resolvedTheme === 'dark' ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
                )}
              </svg>
            )}
          </button>
        </nav>
      </div>
      <main
        id="skip"
        className="flex flex-col justify-center px-8 bg-soft-violet dark:bg-gray-900"
      >
        {children}
        <Footer />
      </main>
    </div>
  );
}
